<template>
  <v-container>
    <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
    <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
    <v-row dense>
      <v-col cols="auto">
        <v-card elevation="6" class="ma-4 pa-2" height="250px" style="overflow-y: auto">
          <v-card-title class="text-subtitle-2 text-lg-h6 text-xl-h6" style="text-align: center">
            <div style="margin-left:auto; margin-right:auto">
              SUS DATOS SEGUROS
            </div>
          </v-card-title>
          <v-card-subtitle style="text-align: center">
            Información para los empleados en cumplimiento de la normativa de protección de datos personales        
          </v-card-subtitle>
          <v-card-tex class="body-2">
          <p>En  Europa y en España existen normas de protección de datos pensadas para proteger  su información personal de obligado cumplimiento para nuestra entidad.<br>
            Por  ello, es muy importante para nosotros que entienda perfectamente qué vamos a  hacer con los datos personales que le pedimos.<br>
            Por  favor, si una vez leída la presente información le queda alguna duda, no dude  en preguntarnos.<br>
            Muchas gracias por su colaboración. </p>
          <ul>
            <li><strong>Contacte  con nuestro delegado de protección de datos</strong></li>
          </ul>
          <p>¿Sabe que en nuestra entidad contamos  con un delegado de protección de datos al cual puede enviar todas sus  reclamaciones, dudas y sugerencias sobre el uso de su información personal?<br>
            Los datos de contacto de nuestro  delegado de protección de datos son los siguientes:</p>
          <ul>
            <li>Dirección de correo electrónico de contacto: aeneriz@audidat.com</li>
          </ul>
          <ul>
            <li><strong>¿Para  qué vamos a usar sus datos?</strong></li>
          </ul>
          <p>Con carácter general, sus datos personales  serán usados para la ejecución del contrato de trabajo y el mantenimiento de la  relación laboral con nuestra entidad.<br>
            En este sentido, su información personal  será usada para fines directamente relacionados con el cumplimiento de la  relación laboral, tales como:</p>
          <ul>
            <li>Cumplimiento de la legislación en materia de Derecho  Laboral.</li>
            <li>Cumplimiento de la legislación en materia de  Seguridad y Salud en el Trabajo.</li>
            <li>Control horario y de presencia.</li>
            <li>Formación del personal.</li>
            <li>Gestión de los recursos humanos en general.</li>
          </ul>
          <p>Por último, pueden existir situaciones  en las que, previa adopción de todas las garantías oportunas para el respeto de  sus derechos y libertades, pueda ser necesaria la utilización de medidas  tecnológicas tales como el uso de sistemas de videovigilancia o de  geolocalización, en cuyo caso le informaremos de manera clara y sencilla a  través de enconos gráficos acompañados de los textos oportunos sobre los fines  de la utilización de dichas medidas.</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong>¿Por  qué necesitamos usar sus datos?</strong></li>
          </ul>
          <p>Sus datos personales son necesarios para  la ejecución del contrato de trabajo y poder mantener la relación laboral con  nuestra entidad, lo que nos permite el uso de su información dentro de la  legalidad.<br>
            No obstante, hay determinadas  situaciones en las cuales necesitaremos su permiso previo para poder realizar  determinadas actividades, como poder publicar su imagen en nuestra página web.  Para ello, pondremos a su disposición una serie de casillas que le permitirán  decidir de manera clara y sencilla sobre el uso de su información  personal.</p>
          <ul>
            <li><strong>¿Quién  va a conocer la información que le pedimos?</strong></li>
          </ul>
          <p>Con carácter general, sólo el personal  de nuestra entidad que, con motivo de sus funciones, esté debidamente  autorizado para el acceso a la información de los trabajadores, podrá tener  conocimiento de su información personal. <br>
            En este sentido, todo el personal de  nuestra entidad que pueda tener acceso a su información ha de suscribir  previamente la Política de Uso del sistema de información, incluyendo el  correspondiente deber de secreto, con el fin de garantizar la seguridad y  confidencialidad de sus datos personales.<br>
            De igual modo, podrán tener conocimiento  de su información personal aquellas entidades que necesiten tener acceso a la  misma para la ejecución del contrato de trabajo y poder mantener la relación  laboral con nuestra entidad. Así por ejemplo, nuestro banco necesita conocer su  número de cuenta bancaria para tramitar el pago de su nómina.<br>
            De la misma manera, tendrán conocimiento  de su información aquellas entidades públicas o privadas a las cuales estemos  obligados a facilitar sus datos personales con motivo del cumplimiento de  alguna ley. Así por ejemplo, la Ley Tributaria y la Ley de la Seguridad Social  nos obligan a facilitar a la Agencia Tributaria y a la Tesorería General de la  Seguridad Social determinada información sobre nuestros empleados para cumplir  con las obligaciones tributarias y con los seguros sociales.<br>
            Informarle, por último, que nuestra entidad puede contratar con empresas externas determinados servicios como la  gestión de las nóminas de los empleados, prevención de riesgos laborales,  formación del personal, etc. En tales casos, nuestra entidad elegirá siempre  empresas comprometidas con el cumplimiento de las normas de protección de datos  y firmará con ellas los correspondientes contratos que garanticen su responsabilidad  en relación con la seguridad y confidencialidad de los datos personales de  nuestros empleados.<br>
            En el caso de que, al margen de los supuestos comentados, necesitemos dar a conocer su información personal a otras entidades, le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir a este  respecto.</p>
          <ul>
            <li><strong>¿Cómo  vamos a proteger sus datos?</strong></li>
          </ul>
          <p>Protegeremos sus datos con medidas de  seguridad eficaces en función de los riesgos que conlleve el uso de su  información. Para ello, nuestra entidad ha aprobado una Política de Protección  de Datos y se realizan controles y auditorías anuales para verificar que sus  datos personales están seguros en todo momento.</p>
          <ul>
            <li><strong>¿Enviaremos  sus datos a otros países?</strong></li>
          </ul>
          <p>En el mundo hay países que son seguros  para sus datos y otros que no lo son tanto. Así por ejemplo, la Unión Europea  es un entorno seguro para sus datos. Nuestra política es no enviar su  información personal a ningún país que no sea seguro desde el punto de vista de  la protección de sus datos.<br>
            En el caso de que, con motivo de la  ejecución del contrato de trabajo o el mantenimiento de la relación laboral,  sea imprescindible enviar sus datos a un país que no sea tan seguro como  España, siempre le mantendremos informado al respecto y aplicaremos medidas de  seguridad eficaces que reduzcan los riesgos del envío de su información  personal a otro país. </p>
          <ul>
            <li><strong>¿Durante  cuánto tiempo vamos a conservar sus datos?</strong></li>
          </ul>
          <p>Conservaremos sus datos durante la  relación laboral y mientras nos obliguen las leyes. Una vez finalizados los  plazos legales aplicables, procederemos a eliminarlos de forma segura y  respetuosa con el medio ambiente. <br>
            Informarle, a título orientativo, que la  Ley sobre Infracciones y Sanciones en el Orden Social obliga a conservar la  información general durante nuestra relación laboral durante cuatro años. </p>
          <ul>
            <li><strong>¿Cuáles  son sus derechos de protección de datos?</strong></li>
          </ul>
          <p>En cualquier momento puede dirigirse a  nosotros para saber qué información tenemos sobre usted, rectificarla si fuese  incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que  ello sea legalmente posible. <br>
            Para solicitar alguno de estos derechos,  deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia  de su DNI, para poder identificarle. </p>
          <ul>
            <li>Nuestra denominación: <strong>FLAMENYCOM SL como empresa matriz y corresponsable del grupo  formado por los responsables de tratamiento empleadores a continuación  señalados: FRUTOS SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA  ARAGONESA DE CALIDAD SL, CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL,  TOSTADOS DE CALIDAD DE CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO  ROJO SL, EL RINCON DE CONVENIENCIA SL.</strong><strong> </strong></li>
            <li>Nuestra dirección: <strong>Calle Profesor Tierno Galván 4, CP 50007, Zaragoza (Zaragoza)</strong></li>
          </ul>
          <p>En las oficinas de nuestra entidad  disponemos de formularios específicos para solicitar dichos derechos y le  ofrecemos nuestra ayuda para su cumplimentación.<br>
            Para saber más sobre sus derechos de  protección de datos, puede consultar la página  web de la Agencia Española de Protección de Datos (<a href="http://www.aepd.es">www.aepd.es</a>).</p>
          <ul>
            <li><strong>¿Puede  retirar su consentimiento si cambia de opinión en un momento posterior?</strong></li>
          </ul>
          <p>Usted puede retirar su consentimiento si  cambia de opinión sobre el uso de sus datos con fines concretos en cualquier  momento. <br>
            Así, por ejemplo, si usted en su día  estuvo conforme en que publicásemos su imagen en nuestra página web, pero ya no  desea que su imagen aparezca en la misma, puede </p>
          <p>hacérnoslo constar a través del  formulario de oposición al tratamiento disponible en las oficinas de nuestra  entidad.</p>
          <ul>
            <li><strong>En  caso de que entienda que sus derechos han sido desatendidos, ¿dónde puede  formular una reclamación?</strong></li>
          </ul>
          <p>En caso de que entienda que sus derechos  han sido desatendidos por nuestra entidad, puede formular una reclamación en la  Agencia Española de Protección de Datos, a través de alguno de los medios  siguientes:</p>
          <ul>
            <li>Sede electrónica: www.aepd.es</li>
            <li>Dirección postal:</li>
          </ul>
          <p>Agencia Española de Protección de Datos <br>
            C/ Jorge Juan, 6<br>
            28001-Madrid </p>
          <ul>
            <li>Vía telefónica:</li>
          </ul>
          <p>Telf. 901 100 099 <br>
            Telf. 91 266 35 17<br>
            Formular una reclamación en la Agencia  Española de Protección de Datos no conlleva ningún coste y no es necesaria la  asistencia de abogado ni procurador. </p>
          <ul>
            <li><strong>¿Usaremos  sus datos para otros fines?</strong></li>
          </ul>
          <p>Nuestra política es no usar sus datos  para otras finalidades distintas a las que le hemos explicado. Si, no obstante,  necesitásemos usar sus datos para actividades distintas, siempre le  solicitaremos previamente su permiso a través de opciones claras que le  permitirán decidir al respecto.</p>      
          </v-card-tex>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-n6">
      <v-col cols="auto">
        <v-checkbox 
          label="He sido debidamente informado sobre el tratamiento de mis datos personales durante el desempeño de la relación laboral"
          class="ml-4"
          dense
          v-model="PerInfDatPer"
          >
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <p class="ml-4 mr-4 mb-0 font-weight-bold">Imágenes </p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <p class="ml-8 mr-8">Consiento la publicación de mi imagen en la página web de la entidad, Internet y otros medios similares para difundir las actividades de su entidad:</p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="PerConsImg">
          <v-radio
            label="Si"
            value="true"
          >
          </v-radio>
          <v-radio
            label="No"
            value="false"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <p class="ml-4 mr-4 mb-0 font-weight-bold">Comunicaciones informativas</p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <p class="ml-8 mr-8">Consiento el uso de mis datos personales para recibir comunicaciones informativas sobre productos, servicios, eventos y noticias de su entidad:</p>
      </v-col>
    </v-row>
    
    <v-row dense>
      <v-col cols="auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="PerConsInf">
          <v-radio
            label="Si"
            value="true"
          >
          </v-radio>
          <v-radio
            label="No"
            value="false"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <p class="ml-4 mr-4 mb-0 font-weight-bold">Comunicaciones informativas colaboradores</p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <p class="ml-8 mr-8">Consiento el uso de mis datos personales para recibir comunicaciones informativas sobre productos, servicios, eventos y noticias de entidades con las que FRUTOS SECOS EL RINCON SL haya suscrito acuerdos de colaboración comerciales:</p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="PerConsInfCol">
          <v-radio
            label="Si"
            value="true"
          >
          </v-radio>
          <v-radio
            label="No"
            value="false"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <!-- <p class="ml-4 mr-4 mb-0 font-weight-bold">Cesión de sus datos personales</p>
    <p class="ml-8 mr-8">Consiento la cesión de mis datos personales a:</p>
    <v-text-field
      class="ml-8"
      label="Entidad"
      style="width: 300px"
      dense
      outlined
    >
    </v-text-field>
    <v-text-field
      class="ml-8 mt-n3"
      label="Finalidad"
      style="width: 300px"
      dense
      outlined
    >
    </v-text-field>

    <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="PerConsCes">
      <v-radio
        label="Si"
        value="true"
      >
      </v-radio>
      <v-radio
        label="No"
        value="false"
      >
      </v-radio>
    </v-radio-group> -->

    <template v-if="$store.state.per.EmpId == 1 || $store.state.per.EmpId == 2 || $store.state.per.EmpId == 3 || $store.state.per.EmpId == 11">
      <v-row dense>
        <v-col cols="auto">
          <p class="ml-4 mr-4 mb-0 font-weight-bold">Comunicación datos de hijos/as menores a su cargo (Regalo de cumpleaños)</p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="auto">
          <p class="ml-8 mr-8">Consiento el tratamiento de los datos de mi hijo/a menor a mi cargo para que su entidad pueda realizarle un regalo con motivo de su cumpleaños (en caso afirmativo , cumplimente los datos del menor):</p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="auto">
          <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="PerConsHij">
            <v-radio
              label="Si"
              value="true"
            >
            </v-radio>
            <v-radio
              label="No"
              value="false"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="8" lg="9" xl="10">
          <v-text-field
            class="ml-8"
            label="Nombre y apellidos de su hijo/a o menor a su cargo"
            dense
            outlined
            v-model="PerHij1Nom"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" lg="3" xl="2">
          <v-text-field
            label="Fecha de nacimiento"
            dense
            outlined
            v-model="PerHij1FecNac"
            type="date"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="mt-n6">
        <v-col cols="8" lg="9" xl="10">
          <v-text-field
            class="ml-8"
            label="Nombre y apellidos de su hijo/a o menor a su cargo"
            dense
            outlined
            v-model="PerHij2Nom"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" lg="3" xl="2">
          <v-text-field
            label="Fecha de nacimiento"
            dense
            outlined
            v-model="PerHij2FecNac"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="mt-n6">
        <v-col cols="8" lg="9" xl="10">
          <v-text-field
            class="ml-8"
            label="Nombre y apellidos de su hijo/a o menor a su cargo"
            dense
            outlined
            v-model="PerHij3Nom"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" lg="3" xl="2">
          <v-text-field
            label="Fecha de nacimiento"
            dense
            outlined
            v-model="PerHij3FecNac"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </template>


    <!-- </div> -->

    <v-row dense>
      <v-col cols="auto">
        <v-card elevation="6" class="ma-4 pa-2" height="250px" style="overflow-y: auto">
          <v-card-title class="text-subtitle-2 text-lg-h6 text-xl-h6" style="text-align: center">
            <div style="margin-left:auto; margin-right:auto">
              CLAÚSULA INFORMATIVA PARA LOS EMPLEADOS<br>
              VIDEOVIGILANCIA CON GRABACIÓN
            </div>
          </v-card-title>
          <v-card-subtitle style="text-align: center">
              Información en cumplimiento de lo establecido en la normativa de protección de datos
          </v-card-subtitle>
          <v-card-tex class="body-2">
            <p>En cumplimiento de lo establecido en los artículos 12 a 14 del  Reglamento General de Protección de Datos y en el artículo 22 de la Ley  Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, FLAMENYCOM  SL informa al trabajador de modo expreso, preciso e inequívoco de los  siguientes aspectos:</p>
            <ol start="1" type="1">
              <li>De la existencia de la actividad del tratamiento de VIDEOVIGILANCIA, cuya finalidad es el tratamiento de las imágenes recogidas por el sistema de cámaras y/o videocámaras de la entidad para garantizar la seguridad de las personas y bienes propios de la entidad, así como el cumplimiento de requisitos de producción y seguridad laboral.</li>
              <li>Los destinatarios de las citadas imágenes serán los siguientes:</li>
            </ol>
            <ul>
              <li>El responsable de la actividad del tratamiento: FLAMENYCOM  SL como empresa corresponsable del grupo formado por los responsables de  tratamiento empleadores a continuación señalados: FRUTOS SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL DE FRANQUICIAS  EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y LEON SL, TOSTADOS  DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE CONVENIENCIA SL.</li>
              <li>La empresa de seguridad encargada del tratamiento (en el supuesto de que ésta  tenga acceso a las imágenes): SOLUCIONES INTELIGENTES TECNOLOGICAS SL</li>
            </ul>
            <ol start="3" type="1">
              <li>Dichas cámaras y/o videocámaras sólo captarán imágenes de los espacios indispensables para las finalidades señaladas. En ningún caso, están instaladas en espacios que puedan ser lesivos para la intimidad del trabajador tales como vestuarios, baños, taquillas o zonas de descanso.</li>
              <li>A las imágenes grabadas accederá sólo el personal autorizado que hayan suscrito el correspondiente compromiso de confidencialidad y normas de uso del sistema de información. Las imágenes serán conservadas durante un plazo máximo de un mes desde su captación.</li>
              <li>La base jurídica del tratamiento, en cada caso, es la siguiente:</li>
            </ol>
            <ul>
              <li>En relación con garantizar la seguridad de las personas y bienes propios de la  entidad, la Ley de Seguridad Privada.</li>
              <li>En relación con garantizar el cumplimiento de requisitos de producción y seguridad  laboral, el artículo 20.3 del Estatuto de los Trabajadores, dentro de los  márgenes de la proporcionalidad establecidos por la jurisprudencia del TC.</li>
            </ul>
            <ol start="6" type="1">
              <li>En cualquier momento puede dirigirse a nosotros para saber si tenemos imágenes sobre usted o borrarlas en el caso de que ello sea legalmente posible. Para ello, deberá realizar una solicitud escrita, junto con una fotografía suya reciente y una fotocopia de su  DNI, a la siguiente dirección: FLAMENYCOM SL,  Calle Profesor Tierno Galván 4, CP 50007,  Zaragoza (Zaragoza)</li>
            </ol>

            <ol start="7" type="1">
              <li>En caso de que entienda que sus derechos han sido desatendidos, puede formular una reclamación a la Agencia Española de Protección de Datos (www.aepd.es).</li>
            </ol>        
          </v-card-tex>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-n6">
      <v-col cols="auto">
        <v-checkbox 
          label="He sido debidamente informado sobre el tratamiento de videovigilancia"
          class="ml-4"
          dense
          v-model="PerInfVidVig"
          >
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <v-card elevation="6" class="ma-4 pa-2" height="250px" style="overflow-y: auto">
          <v-card-title class="text-subtitle-2 text-lg-h6 text-xl-h6" style="text-align: center">
            <div style="margin-left:auto; margin-right:auto">
              POLÍTICA DE USO DEL SISTEMA DE INFORMACIÓN
            </div>
          </v-card-title>
          <v-card-subtitle style="text-align: center">
            Normas de uso del conjunto de tratamientos, programas, soportes y equipos empleados para el tratamiento de datos de carácter personal y otras informaciones protegidas por el deber de secreto responsabilidad de FLAMENYCOM SL
          </v-card-subtitle>
          <v-card-tex class="body-2">
            <ul>
              <li><strong>Objetivo del documento</strong><strong> </strong></li>
            </ul>
            <p>El 4 de mayo  de 2016, se publicó en el Diario Oficial de la Unión Europea el Reglamento (UE)  2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo  a la protección de las personas físicas en lo que respecta al tratamiento de  datos personales y a la libre circulación de estos datos y por el que se deroga  la Directiva&nbsp;95/46/CE (Reglamento general de protección de datos) (DOUE L  119/1, 04-05-2016), en adelante RGPD.<br>
              Así mismo,  la Agencia Española de Protección de Datos plasmó, en su Plan Estratégico  2015-2019, su voluntad de que los responsables del tratamiento alcancen un  elevado cumplimiento de las obligaciones que la normativa de protección de  datos les impone, fomentando una cultura de la protección de datos que suponga  una clara mejora de la competitividad, compatible con el desarrollo económico.<br>
              En este  sentido, el Considerando 39 del Reglamento europeo señala que &ldquo;los datos  personales deben tratarse de un modo que garantice una seguridad y  confidencialidad adecuadas de los datos personales, inclusive para impedir el  acceso o uso no autorizados de dichos datos y del equipo utilizado en el  tratamiento&rdquo;.<br>
              A este  respecto, el artículo 5 del Reglamento (UE) 2016/679, bajo la rúbrica  &ldquo;Principios relativos al tratamiento&rdquo;, establece que los datos personales  deberán ser &ldquo;tratados de tal manera que se garantice una seguridad adecuada de  los datos personales, incluida la protección contra el tratamiento no  autorizado o ilícito y contra su pérdida, destrucción o daño accidental,  mediante la aplicación de medidas técnicas u organizativas apropiadas  («integridad y confidencialidad»)&rdquo; (art. 5.1 f RGPD). Así mismo, el responsable  del tratamiento será responsable del cumplimiento de lo dispuesto en dicho  apartado&nbsp;y deberá ser capaz de demostrarlo («responsabilidad proactiva»)  (art. 5.4 RGPD). <br>
              En su  consecuencia, la Dirección / Órgano de Gobierno de FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE  CONVENIENCIA SL, apuesta por una política proactiva de cumplimiento en  pos de conseguir que en el desarrollo de sus fines se respete de forma activa  el derecho fundamental a la protección de datos.</p>
            <p>De tal modo,  el artículo 32 del Reglamento (UE) 2016/679, bajo el epígrafe &ldquo;Seguridad del  tratamiento&rdquo;, establece lo siguiente:<br>
              <em>1.&nbsp;&nbsp;&nbsp;Teniendo en cuenta el estado de  la técnica, los costes de aplicación, y la naturaleza, el alcance, el contexto  y los fines del tratamiento, así como riesgos de probabilidad y gravedad  variables para los derechos y libertades de las personas físicas, el  responsable y el encargado del tratamiento aplicarán medidas técnicas y  organizativas apropiadas para garantizar un nivel de seguridad adecuado al  riesgo, que en su caso incluya, entre otros:</em><br>
              <em>a) la seudonimización y el cifrado de datos  personales;</em><br>
              <em>b) la capacidad de garantizar la confidencialidad,  integridad, disponibilidad y resiliencia permanentes de los sistemas y  servicios de tratamiento;</em><br>
              <em>c) la capacidad de restaurar la disponibilidad y el  acceso a los datos personales de forma rápida en caso de incidente físico o  técnico;</em><br>
              <em>d) un proceso de verificación, evaluación y  valoración regulares de la eficacia de las medidas técnicas y organizativas  para garantizar la seguridad del tratamiento.</em><br>
              Así, <em>ex</em> art. 32.4 RGPD, el responsable del  tratamiento deberá tomar las medidas para garantizar que cualquier persona que  actúe bajo su autoridad y tenga acceso a datos personales solo pueda tratar  dichos datos siguiendo las instrucciones del responsable.<br>
              De otro  lado, a finales del año 2018&nbsp;fue aprobada en España la Ley Orgánica  3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los  derechos digitales (BOE núm. 294, 06-12-2018) (LOPDGDD). Dicha Ley Orgánica  adapta el ordenamiento jurídico español al modelo establecido en el Reglamento  general de protección de datos, introduciendo nuevos aspectos mediante el  desarrollo de materias contenidas en el mismo.<br>
              En tal  sentido, la citada LOPDGDD recoge un artículo específico relativo al deber de  confidencialidad, señalando lo siguiente:<br>
              <em>Artículo 5. Deber de confidencialidad.</em><br>
              <em>1. Los responsables y encargados del tratamiento de  datos así como todas las personas que intervengan en cualquier fase de este  estarán sujetas al deber de confidencialidad al que se refiere el artículo  5.1.f) del Reglamento (UE) 2016/679.</em><br>
              <em>2. La obligación general señalada en el apartado  anterior será complementaria de los deberes de secreto profesional de  conformidad con su normativa aplicable.</em><br>
              <em>3. Las obligaciones establecidas en los apartados  anteriores se mantendrán aun cuando hubiese finalizado la relación del obligado  con el responsable o encargado del tratamiento.</em> <br>
              Como  corolario de todo lo anterior, el presente documento se elabora con el objeto  de establecer la Política de uso del sistema de información con la finalidad de  dar cumplimiento con lo establecido en los artículos 5.1 f) y 32.1 y 32.4 del  Reglamento (UE) 2016/679 y en el artículo 5 de la Ley Orgánica 3/2018.</p>
            <p>&nbsp;</p>
            <ul>
              <li><strong>Ámbito  de aplicación</strong></li>
            </ul>
            <p>Las normas  contenidas en la presente Política de Uso serán de aplicación a todos los  usuarios del sistema de información responsabilidad de FLAMENYCOM SL como empresa corresponsable del grupo formado por los  responsables de tratamiento empleadores a continuación señalados: FRUTOS SECOS  EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL  DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y  LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE CONVENIENCIA  SL., entendiéndose por tal el conjunto de tratamientos, programas,  soportes y equipos empleados para el tratamiento de datos de carácter personal  y otras informaciones protegidas por el deber de secreto.</p>
            <ul>
              <li><strong>Confidencialidad  y secreto</strong></li>
            </ul>
            <p>El usuario  del sistema de información responsabilidad de FLAMENYCOM  SL como empresa matriz y corresponsable del grupo formado por los responsables  de tratamiento empleadores a continuación señalados: FRUTOS SECOS EL RINCON SL,  MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL DE  FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y LEON  SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE CONVENIENCIA SL,  debe guardar la debida confidencialidad sobre los hechos, informaciones, conocimientos,  documentos, objetos y cualesquiera otros elementos protegidos por el secreto, a  los que tenga acceso con motivo de la relación con el responsable del  tratamiento.<br>
              En tal  sentido, y sin carácter limitativo o excluyente, el citado deber de confidencialidad  y secreto comprende la siguiente información:</p>
            <ul>
              <li>Cualquier información sobre personas físicas identificadas o identificables, protegida  por la normativa sobre protección de las personas físicas en lo que respecta al  tratamiento de datos personales.</li>
              <li>Cualquier información protegida por la Ley Orgánica 1/1982, de 5 de mayo, sobre  protección civil del derecho al honor, a la intimidad personal y familiar y a  la propia imagen.</li>
              <li>Cualquier información sujeta al deber de secreto profesional.</li>
              <li>Cualquier información protegida por la normativa sobre propiedad intelectual e  industrial.</li>
              <li>Los  conocimientos técnicos y la información empresarial no divulgados (secretos  comerciales).</li>
              <li>Cualquier otra información que por su naturaleza no pueda ser revelada a terceros ajenos  al responsable del tratamiento y que, por lo tanto, no sea de conocimiento  público.</li>
            </ul>
            <p>El cumplimiento de dicha obligación subsistirá aun después de finalizar la  relación con FLAMENYCOM SL como empresa matriz y  corresponsable del grupo formado por los responsables de tratamiento empleadores  a continuación señalados: FRUTOS SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL,  PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL DE FRANQUICIAS EL RINCON SL,  GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL,  EL GALLO ROJO SL y EL RINCON DE CONVENIENCIA SL. </p>
            <ul>
              <li><strong>Instrucciones  del responsable del tratamiento</strong></li>
            </ul>
            <p>El usuario  del sistema de información debe cumplir con las normas de seguridad que afecten  al desarrollo de sus funciones en el marco de la relación con el responsable  del tratamiento, que asimismo son de obligado cumplimiento para las personas  con acceso al conjunto de tratamientos, programas, soportes y equipos empleados  para el tratamiento de datos de carácter personal y otras informaciones  protegidas por el deber de secreto responsabilidad de FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL y EL RINCON DE  CONVENIENCIA SL<br>
              Asimismo, debe usar los datos de  carácter personal responsabilidad de FLAMENYCOM  SL como empresa matriz y corresponsable del grupo formado por los responsables  de tratamiento empleadores a continuación señalados: FRUTOS SECOS EL RINCON SL,  MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL DE  FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y LEON  SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE CONVENIENCIA SL,  exclusivamente con las finalidades determinadas, explícitas y legítimas,  necesarias para el desarrollo de sus funciones en la citada entidad, para las  cuales haya sido autorizado en el marco de la relación con la misma.<br>
              De igual manera, se le informa de la  prohibición de acceder a los datos de carácter personal responsabilidad de FLAMENYCOM SL como empresa matriz y corresponsable del  grupo formado por los responsables de tratamiento empleadores a continuación  señalados: FRUTOS SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA  ARAGONESA DE CALIDAD SL, CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL,  TOSTADOS DE CALIDAD DE CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO  ROJO SL, EL RINCON DE CONVENIENCIA SL, que no sean precisos para el  desarrollo de sus funciones en el marco de la relación con la citada entidad,  sin autorización expresa de la misma.<br>
              El cumplimiento de dichas obligaciones  subsistirá aun después de finalizar la relación con FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL y EL RINCON DE  CONVENIENCIA SL.</p>
            <p>&nbsp;</p>
            <ul>
              <li><strong>Normas de seguridad</strong></li>
            </ul>
            <p>A  continuación, se resumen las principales obligaciones en materia de protección  de datos para las personas con acceso al conjunto de tratamientos, programas,  soportes y, en su caso, equipos empleados para el tratamiento de datos de  carácter personal responsabilidad de FLAMENYCOM  SL como empresa matriz y corresponsable del grupo formado por los responsables  de tratamiento empleadores a continuación señalados: FRUTOS SECOS EL RINCON SL,  MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL, CENTRAL DE  FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE CASTILLA Y LEON  SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, y EL RINCON DE CONVENIENCIA SL:</p>
            <ul>
              <li>Cada  usuario deberá acceder exclusivamente a aquellos datos o recursos que precise  para el desarrollo de sus funciones, previa autorización del responsable del  tratamiento.</li>
              <li>Cada  usuario será responsable de la confidencialidad de su contraseña. En caso de  que la misma sea conocida fortuita o fraudulentamente por personas no  autorizadas, deberá notificarlo como incidencia y solicitar inmediatamente el  cambio de la misma al departamento de informática para que sea actualizada en  el registro de contraseñas.</li>
              <li>Las  contraseñas deberán ser suficientemente complejas y difícilmente adivinables  por terceros, evitando el uso del propio identificador como contraseña o  palabras sencillas, el nombre propio, fecha de nacimiento, etc.</li>
            </ul>
            <p>Para ello se seguirán las siguientes  pautas en la elección de las contraseñas:</p>
            <ul>
              <li>Deberán tener una longitud mínima de 8 caracteres alfanuméricos.</li>
              <li>No deberán coincidir con el código de usuario.</li>
              <li>No deberán estar basadas en cadenas de caracteres que sean fácilmente asociables al usuario (nombre, apellidos, ciudad  y fecha de nacimiento, DNI, nombres de familiares, matrícula del coche, etc.).</li>
              <li>Tanto las pantallas como las impresoras u otro tipo de dispositivos conectados al puesto de trabajo deberán estar físicamente ubicados en lugares que garanticen la  confidencialidad de los datos de carácter personal.</li>
              <li>Cuando el responsable de un puesto de trabajo lo abandone, bien temporalmente o bien  al finalizar su turno de trabajo, deberá dejarlo en un estado que impida la  visualización de los datos de carácter personal, como por ejemplo un protector  de pantalla con contraseña. Tras la aplicación de medidas técnicas en proceso  por parte del responsable del tratamiento, esta medida será automatizada. La  reanudación del trabajo implicará la desactivación de la pantalla protectora  con la introducción de la contraseña correspondiente.</li>
              <li>En el caso de las impresoras, el usuario deberá asegurarse de que no quedan  documentos impresos en la bandeja de salida que contengan datos de carácter  personal. Si las impresoras son compartidas con otros usuarios no autorizados  para acceder a los citados datos, los responsables de cada puesto deberán  retirar los documentos conforme vayan siendo impresos.</li>
              <li>Mientras  la documentación con datos de carácter personal no se encuentre archivada en  los correspondientes dispositivos de almacenamiento, por estar en proceso de  revisión o tramitación, ya sea previo o posterior a su archivo, la persona que  se encuentre al cargo de la misma deberá custodiarla e impedir en todo momento  que pueda ser accedida por persona no autorizada.</li>
              <li>Los puestos de trabajo desde los que se tiene acceso a los datos de carácter  personal tendrán una configuración fija en sus aplicaciones y sistema operativo  que solo podrá ser cambiada bajo autorización del responsable del tratamiento. </li>
              <li>Queda expresamente prohibido el tratamiento de datos de carácter personal con  programas ofimáticos, como procesadores de texto u hojas de cálculo, sin  comunicarlo para su aprobación al responsable del tratamiento para que se  proceda a implantar las medidas de seguridad adecuadas.</li>
              <li>Cuando los datos de carácter personal se almacenen en dispositivos portátiles o se  traten fuera de los locales del responsable del tratamiento, el usuario deberá  solicitar la autorización previa del responsable del tratamiento, debiendo  garantizarse, en todo caso, el nivel de seguridad adecuado al riesgo de la  actividad de tratamiento.</li>
              <li>Cuando el usuario tenga conocimiento de cualquier anomalía que afecte o pudiera afectar a la seguridad de los  datos, deberá  comunicarla sin dilación indebida al responsable del tratamiento para que se  haga constar el tipo de incidencia, el momento en que se ha producido, o en su  caso, detectado, la persona que realiza la notificación, a quién se le  comunica, los efectos que se hubieran derivado de la misma y las medidas  correctoras aplicadas.</li>
              <li>Cuando el usuario tenga conocimiento de una violación de la seguridad que ocasione la  destrucción, pérdida o alteración accidental o ilícita de datos personales  transmitidos, conservados o tratados de otra forma, o la comunicación o acceso  no autorizados a dichos datos, deberá comunicarla sin dilación indebida al  responsable del tratamiento para que se notifique a la autoridad de control competente  y, en su caso, a los interesados.</li>
              <li>Los  soportes que contengan datos de carácter personal deberán ser almacenados en  lugares a lo que no tengan acceso personas no autorizadas para el uso de los  mismos.</li>
              <li>La  salida de soportes informáticos y documentos que contengan datos de carácter  personal, incluidos los comprendidos y/o anejos a un correo electrónico, fuera  de los locales bajo el control del responsable del tratamiento deberá ser  autorizada por el responsable del tratamiento.</li>
              <li>La  política de salida de soportes informáticos y documentos que contengan datos de  carácter personal, incluidos los comprendidos y/o anejos a un correo  electrónico, está en proceso de adaptación para que se realicen estas prácticas  mediante cifrado de dichos datos o bien utilizando otro mecanismo que garantice  que dicha información no sea accesible o manipulada durante su transporte, en  aquellos supuestos en que la actividad de tratamiento sea considerada de alto  riesgo. </li>
              <li>Así  mismo, se deberán cifrar los datos de carácter personal que contengan los  dispositivos portátiles cuando éstos se encuentren fuera de las instalaciones  que están bajo el control del responsable del fichero, en aquellos supuestos en  que la actividad de tratamiento sea considerada de alto riego.</li>
              <li>El usuario  deberá borrar o destruir aquellos ficheros temporales o copias de documentos  que hubiese creado exclusivamente para la realización de trabajos temporales o  auxiliares una vez que haya dejado de ser necesario para los fines que  motivaron su creación.</li>
              <li>Se  prohíbe el uso de dispositivos personales (portátiles, smartphones, tablets),  propiedad del empleado, para el tratamiento de datos de carácter personal responsabilidad de FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE  CONVENIENCIA SL, salvo que medie autorización expresa del  responsable del tratamiento y previa adopción de las medidas de seguridad  adecuadas al riesgo de la actividad de tratamiento.</li>
            </ul>
            <ul>
              <li><strong>Normas  de uso del correo electrónico</strong></li>
            </ul>
            <p>Los usuarios  de las cuentas de correo electrónico titularidad de FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE  CONVENIENCIA SL, deben cumplir las siguientes normas de uso:</p>
            <ul>
              <li>La  cuenta de correo electrónico proporcionada no debe ser utilizada para fines  privados, personales o lúdicos, ya que constituye una herramienta de trabajo. </li>
              <li>Cuando  se envíen mensajes de correo electrónico a múltiples destinatarios, se ha de  utilizar el campo &ldquo;Con Copia Oculta (CCO)&rdquo; para introducir las direcciones de  los mismos, a fin de salvaguardar los deberes de confidencialidad y secreto.</li>
              <li>Queda  prohibido el envío de comunicaciones publicitarias o promocionales por correo  electrónico que previamente no hayan sido solicitadas o expresamente  autorizadas por los destinatarios de las mismas.</li>
              <li>Tras  la adaptación técnica de la política de privacidad a los procedimientos de  envío de datos de carácter personal del responsable de tratamiento, quedará  prohibida, en aquellos supuestos en que la actividad de tratamiento sea  considerada de alto riesgo, la comunicación de estos sin aplicar mecanismos de  cifrado o cualesquiera otros que garanticen que dicha información no sea  accesible por persona no autorizada.</li>
              <li>Queda  prohibido el envío de mensajes de correo electrónico de carácter racista,  xenófobo, pornográfico, sexista, de apología del terrorismo, peligroso,  amenazador, difamatorio, obsceno, o que vulneren de cualquier otro modo el  valor jurídico fundamental de la dignidad de la persona. </li>
              <li>Queda  prohibido el envío de mensajes de correo electrónico que vulneren los derechos  fundamentales a la protección de datos de carácter personal, a la intimidad, al  honor, y/o a la propia imagen.</li>
              <li>Queda  prohibido el envío de mensajes de correo electrónico que vulneren los derechos  de propiedad intelectual o industrial.</li>
              <li>Queda  prohibido el envío de mensajes de correo electrónico que violen cualquier otra  normativa vigente.</li>
            </ul>
            <ul>
              <li><strong>Usos  no aceptables</strong></li>
            </ul>
            <p>Al margen de  las prohibiciones hasta aquí señaladas, tendrán la consideración de usos no  aceptables (y, por ende, prohibidos) del sistema de información los siguientes:</p>
            <ul>
              <li>El  uso del sistema de información  para fines privados, personales, lúdicos o cualesquiera otros no estrictamente  relacionados con el desarrollo de sus funciones en el marco de la relación con  la citada entidad, salvo que medie autorización expresa del responsable del  tratamiento. </li>
              <li>El  acceso a datos o recursos del sistema  de información para los que el usuario no esté debidamente autorizado  por el responsable del departamento de informática.</li>
              <li>Facilitar  el acceso a datos o recursos del  sistema de información a  personas no autorizadas.</li>
              <li>Compartir  datos o recursos con otros usuarios autorizados sin la adopción de las medidas  de seguridad adecuadas al riesgo de la actividad de tratamiento.</li>
              <li>La  realización de acciones cuyo fin sea la obtención de contraseñas de otros  usuarios autorizados, sin que  medie autorización expresa del responsable del departamento de informática.</li>
              <li>Proporcionar  acceso externo desde la propia red de comunicaciones, mediante la instalación  de dispositivos de acceso remoto, salvo que medie autorización expresa del responsable  del departamento de informática.</li>
              <li>La  modificación no autorizada de permisos o privilegios en relación con el acceso  a datos o recursos del sistema de información. </li>
              <li>La  instalación de cualesquiera programas en los equipos del sistema de información  sin que medie autorización expresa del responsable del departamento de  informática. </li>
              <li>No  hacer un uso racional, eficiente y considerado de los recursos proporcionados  por el responsable del tratamiento, tales como: espacio en disco, memoria,  redes de comunicaciones, etc.</li>
              <li>La  destrucción no autorizada de datos o recursos del sistema de información.</li>
              <li>El  intento de causar cualquier tipo de daño físico o lógico al sistema de  información.</li>
            </ul>
            <br clear="all">
            <ul>
              <li><strong>Responsabilidad</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>FLAMENYCOM SL como empresa matriz y corresponsable del grupo formado por  los responsables de tratamiento empleadores a continuación señalados: FRUTOS  SECOS EL RINCON SL, MOSLOCI SL, LOFLA SL, PASTELERIA ARAGONESA DE CALIDAD SL,  CENTRAL DE FRANQUICIAS EL RINCON SL, GOLODOLÇ SL, TOSTADOS DE CALIDAD DE  CASTILLA Y LEON SL, TOSTADOS DE CALIDAD SL, EL GALLO ROJO SL, EL RINCON DE  CONVENIENCIA SL, en virtud de lo  establecido en el artículo 20.3 del texto refundido de la Ley del Estatuto de  los Trabajadores, aprobado por el Real Decreto Legislativo 2/2015, de 23 de  octubre, podrá supervisar, presencialmente o a través de cualesquiera otras herramientas o medidas de control, la  estación de trabajo del usuario, en orden a comprobar la corrección de su uso  de conformidad con la presente Política de Uso, sin perjuicio de la posible  aplicación de otras medidas de carácter preventivo, como la exclusión de  determinadas conexiones.<br>
              El  incumplimiento de las normas de uso del sistema de información, establecidas en  el presente documento, podrá tener como consecuencia la imposición de las  sanciones disciplinarias correspondientes, sin perjuicio del ejercicio de las  acciones laborales, civiles o penales que, en su caso, procedan y las  responsabilidades que de dicho ejercicio se deriven.</p>   
          </v-card-tex>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-n6">
      <v-col cols="auto">
        <v-checkbox 
          label="He sido debidamente informado sobre la política de uso de los sistemas de información"
          class="ml-4"
          dense
          v-model="PerInfPolInf"
          >
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row dense >
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-btn
          color="primary"
          @click="GrabarDatos"
        >
          Grabar datos
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  import router from '../router'
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      valid: false,
      asunto: '',
      asuntoRules: [
        v => !!v || 'El asunto es obligatorio',
      ],
      name: '',
      nameRules: [
        v => !!v || 'El nombre es obligatorio',
      ],
      sugerencia: '',
      sugerenciaRules: [
          v => !!v || 'La sugerencia es obligatoria',
      ],
      PerInfDatPer: false,
      PerInfVidVig: false,
      PerInfPolInf: false,
      PerConsImg: "false",
      PerConsInf: "false",
      PerConsInfCol: "false", 
      PerConsCes: "false",
      PerConsHij: "false",
      PerHij1Nom: "",
      PerHij1FecNac: null,
      PerHij2Nom: "",
      PerHij2FecNac: null,
      PerHij3Nom: "",
      PerHij3FecNac: null,
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
        if (this.valid)
        {
            this.enviar();
        }
      },
      GrabarDatos() {
        var perObj = this.$store.state.per;

       perObj.Usr = null;

       perObj.Emp = null;

       perObj.PerCon = null;
       perObj.PerCatPer = null;
       perObj.Dir = null;

//       perObj.MsjPer = null;
//       perObj.MsjPerCatPer = null;
       perObj.PerEmp = null;
       perObj.PerDirRel = null;


        perObj.PerInfDatPer = this.PerInfDatPer;
        perObj.PerInfVidVig = this.PerInfVidVig;
        perObj.PerInfPolInf = this.PerInfPolInf;

        // perObj.PerConsImg = (this.PerConsImg === 'true');
        // perObj.PerConsInf = (this.PerConsInf === 'true');
        // perObj.PerConsInfCol = (this.PerConsInfCol === 'true');
        // perObj.PerConsCes = (this.PerConsCes === 'true');
        // perObj.PerConsHij = (this.PerConsHij === 'true');

        perObj.PerConsImg = (this.PerConsImg === 'true');
        perObj.PerConsInf = (this.PerConsInf === 'true');
        perObj.PerConsInfCol = (this.PerConsInfCol === 'true');
        perObj.PerConsCes = (this.PerConsCes === 'true');
        perObj.PerConsHij = (this.PerConsHij === 'true');

        perObj.PerHij1Nom = this.PerHij1Nom;
        perObj.PerHij1FecNac = this.PerHij1FecNac;
        perObj.PerHij2Nom = this.PerHij2Nom;
        perObj.PerHij2FecNac = this.PerHij2FecNac;
        perObj.PerHij3Nom = this.PerHij3Nom;
        perObj.PerHij3FecNac = this.PerHij3FecNac;

        var controllerParameters = {
                  Action: "SAVE_RGPD",
                  PerId: this.perId,

                  // PerInfDatPer: (this.PerInfDatPer === 'true'),
                  // PerInfVidVig: (this.PerInfVidVig === 'true'),
                  // PerInfPolInf: (this.PerInfPolInf === 'true'),

                  // PerConsImg: (this.PerConsImg === 'true'),
                  // PerConsInf: (this.PerConsInf === 'true'),
                  // PerConsInfCol: (this.PerConsInfCol === 'true'),
                  // PerConsCes: (this.PerConsCes === 'true'),
                  // PerConsHij: (this.PerConsHij === 'true'),
                  PerInfDatPer: (this.PerInfDatPer === true),
                  PerInfVidVig: (this.PerInfVidVig === true),
                  PerInfPolInf: (this.PerInfPolInf === true),
                  PerConsImg: (this.PerConsImg === 'true'),
                  PerConsInf: (this.PerConsInf === 'true'),
                  PerConsInfCol: (this.PerConsInfCol === 'true'),
                  PerConsCes: (this.PerConsCes === 'true'),
                  PerConsHij: (this.PerConsHij === 'true'),
                  PerHij1Nom: this.PerHij1Nom,
                  PerHij1FecNac: this.PerHij1FecNac,
                  PerHij2Nom: this.PerHij2Nom,
                  PerHij2FecNac: this.PerHij2FecNac,
                  PerHij3Nom: this.PerHij3Nom,
                  PerHij3FecNac: this.PerHij3FecNac

                  // Per: perObj
        }

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result =>{
              if (result.status >= 200 && result.status <= 299)
              {
                alert("Datos grabados con éxito");
                router.push('/');
              }
            })
            .catch(error => {
                    alert(error);
        })        
      },
      enviar () {
        var sugerenciaObj = { MsjCat1Id: 1, MsjNom: this.asunto, MsjTxt: this.sugerencia, UsrOriNom: this.name, EmpId: this.empId };
        // var sugerenciaJson = JSON.stringify(sugerenciaObj);

          var controllerParameters = {
                    Action: "SAVE_MENSAJE",
                    Msj: sugerenciaObj
          }

        var AuthToken = localStorage.getItem('token');
        // axios({ method: "PUT", "url": this.urlRaiz + "/api/mensajes", "data": sugerenciaJson, "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result =>{
              if (result.status >= 200 && result.status <= 299)
              {
                alert("Sugerencia enviada con éxito");
                router.push('/');
              }
            })
            .catch(error => {
                    alert(error);
        })
      }
    },
    mounted() {
      this.PerInfDatPer = this.$store.state.per.PerInfDatPer;
      this.PerInfVidVig = this.$store.state.per.PerInfVidVig;
      this.PerInfPolInf = this.$store.state.per.PerInfPolInf;

      this.PerConsImg = this.$store.state.per.PerConsImg.toString();
      this.PerConsInf = this.$store.state.per.PerConsInf.toString();
      this.PerConsInfCol = this.$store.state.per.PerConsInfCol.toString();
      this.PerConsCes = this.$store.state.per.PerConsCes.toString();
      this.PerConsHij = this.$store.state.per.PerConsHij.toString();

      this.PerHij1Nom = this.$store.state.per.PerHij1Nom;
      this.PerHij2Nom = this.$store.state.per.PerHij2Nom;
      this.PerHij3Nom = this.$store.state.per.PerHij3Nom;

      if (this.$store.state.per.PerHij1FecNac != null) {
        var PerHij1FecNacDate = new Date(this.$store.state.per.PerHij1FecNac);
        this.PerHij1FecNac = PerHij1FecNacDate.getFullYear() + "-" + ((PerHij1FecNacDate.getMonth() + 1).toString().padStart(2, "0")) + "-" + PerHij1FecNacDate.getDate().toString().padStart(2, "0");
      }
      if (this.$store.state.per.PerHij2FecNac != null) {
        var PerHij2FecNacDate = new Date(this.$store.state.per.PerHij2FecNac);
        this.PerHij2FecNac = PerHij2FecNacDate.getFullYear() + "-" + ((PerHij2FecNacDate.getMonth() + 1).toString().padStart(2, "0")) + "-" + PerHij2FecNacDate.getDate().toString().padStart(2, "0");
      }
      if (this.$store.state.per.PerHij3FecNac != null) {
        var PerHij3FecNacDate = new Date(this.$store.state.per.PerHij3FecNac);
        this.PerHij3FecNac = PerHij3FecNacDate.getFullYear() + "-" + ((PerHij3FecNacDate.getMonth() + 1).toString().padStart(2, "0")) + "-" + PerHij3FecNacDate.getDate().toString().padStart(2, "0");
      }
    }
  }
</script>